import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { VideoBlog } from "../../../components/video";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";

const VidImg = require("../../../assets/img/chatbot/hr_chatbot/video_img_hrbot.png");

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");
const image1 = require("../../../assets/images/freshservice_blog/1_1.png");
const image2 = require("../../../assets/images/gusto_blog/1.png");
const image3 = require("../../../assets/images/gusto_blog/2.png");
const image4 = require("../../../assets/images/freshservice_blog/3_3.png");
const image5 = require("../../../assets/images/gusto_blog/3.png");
const image6 = require("../../../assets/images/gusto_blog/4.jpg");
const image7 = require("../../../assets/images/gusto_blog/5.jpg");
const image8 = require("../../../assets/images/gusto_blog/6.jpg");
const image9 = require("../../../assets/images/gusto_blog/7.jpg");
const image10 = require("../../../assets/images/gusto_blog/8.jpg");
const image11 = require("../../../assets/images/gusto_blog/9.png");
const image12 = require("../../../assets/images/gusto_blog/10.png");
const image13 = require("../../../assets/images/gusto_blog/11.jpg");
const image14 = require("../../../assets/images/gusto_blog/12.png");
const image15 = require("../../../assets/images/gusto_blog/13.png");
const image16 = require("../../../assets/images/freshservice_blog/l_1.png");
const image17 = require("../../../assets/images/freshservice_blog/l_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Build Free Gusto Chatbot for automated workplace support."
        description="Build Gusto Chatbot without any coding in minutes Workativ’s platform provides easy tools to quickly build your Gusto chatbot and automate workplace support "
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
        schema={ldSchema}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-container">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header">
                            The Ultimate Guide to Gusto Chatbot
                          </h1>
                        </div>
                      </div>
                      <section className="table_contents_wrapper">
                        <h3 class="font-section-sub-header-small">
                          Table of contents
                        </h3>
                        <div className="table_contents_link font-section-normal-text-medium">
                          <AnchorLink offset={180} href="#section1">
                            1. What is Gusto Chatbot?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section2">
                            2. How does Gusto Chatbot works?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section3">
                            3. What is Gusto Chatbot used for?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section4">
                            4. Best Gusto Chatbot platform
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section5">
                            5. AI-powered Gusto automation with Gusto Chatbot
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section6">
                            6. How to Build Gusto Chatbot Without coding
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section7">
                            7. Business benefits of Gusto chatbot
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section8">
                            8. Conclusion
                          </AnchorLink>
                        </div>
                      </section>

                      {/* Header Section */}
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 market_wrapper_page line-height-2">
                        <p class="font-section-normal-text line-height-2">
                          Human resource (HR) management constitutes a vital
                          part of every business. Yet, HR has traditionally been
                          a highly manual departmental operation. These manual
                          tasks are mostly monotonous and prone to errors. With
                          the advances in AI and automation technologies, HR
                          functions can increase their level of automation and
                          the processes can be handled more efficiently
                          improving employee satisfaction. 
                          <a href="https://www.gartner.com/smarterwithgartner/ai-shows-value-and-gains-traction-in-hr">
                            &nbsp;Gartner shares that 
                          </a>{" "}
                          17% of organizations use AI-based solutions in their
                          HR function and another 30% will do the same in 2022.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                            HR automation
                          </a>
                           is the process of increasing the productivity of HR
                          departments by saving them from tiresome manual tasks
                          and enabling them to concentrate on higher value-added
                          tasks such as decision-making and strategy. By
                          automating repetitive tasks, HR management
                          professionals can capture, create, and update data and
                          streamline various HR tasks for employees. This will
                          help businesses to be more productive, rules-driven,
                          and streamlined. 
                          <a href="https://www.gartner.com/smarterwithgartner/ai-shows-value-and-gains-traction-in-hr">
                            &nbsp;According to Gartner,
                          </a>{" "}
                          there are three main challenges for companies to
                          implement AI and automation powered solutions in their
                          HR processes. 
                        </p>
                        <ol
                          className="font-section-normal-text"
                          style="float: left; width: 100%;"
                        >
                          <li>Funding AI initiatives  </li>
                          <li>Security and privacy concerns</li>
                          <li>
                            The complexity of integrating AI into existing
                            in-house infrastructures {" "}
                          </li>
                        </ol>
                        <p class="font-section-normal-text line-height-2">
                          There are many automated HR systems available on the
                          market today. When investigating a suitable system,
                          you should ask for a demo of the software and its
                          features.  To check if a solution is suitable for your
                          company’s HR processes, you should have answers to the
                          following questions: {" "}
                        </p>
                        <ol
                          className="font-section-normal-text"
                          style="float: left; width: 100%;"
                        >
                          <li>Does this solution satisfy all my needs?    </li>
                          <li>
                            What systems does the solution integrate with and
                            how easy is it to integrate {" "}
                          </li>
                          <li>Is your solution scalable?  </li>
                        </ol>
                        <p class="font-section-normal-text line-height-2">
                          So the question is, how can your business tap into the
                          power of HR process automation? Why with Gusto
                          Chatbot, of course!  {" "}
                        </p>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 market_wrapper_page line-height-2">
                        {/* Section 1 */}
                        <div id="section1">
                          <h2 className="font-section-sub-header-small-bold">
                            What is Gusto Chatbot?
                          </h2>
                          <p class="font-section-normal-text line-height-2">
                            <a href="https://workativ.com/conversational-ai-platform/gusto-chatbot">
                              {" "}
                              Gusto Chatbot
                            </a>{" "}
                            is a platform for scaling your HR support with
                            AI-based self-service automation. Delivering remote
                            support for employees is more important than ever
                            and this chatbot can help automate employee support
                            with intelligent chatbot and process automation,
                            out-of-the-box. Gusto Chatbot can be added to any of
                            your instant messaging apps like Slack or Microsoft
                            Teams so that employees can self-serve their HR
                            queries, on-the-go, anytime.
                          </p>
                        </div>

                        {/* Section 2 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section2"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            How does Gusto Chatbot works?  
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            <a href="https://workativ.com/conversational-ai-platform/gusto-chatbot">
                              Gusto chatbots
                            </a>{" "}
                            use Natural Language Processing to understand a
                            user’s intention or utterances for initiating a
                            conversation with it as well as to extract required
                            data from a user’s query and pass it to the
                            automation to resolve the user’s issue in
                            real-time. So, think of how intelligent chatbots
                            work, but with the Gusto chatbot, it can not only
                            have those benefits, but also extend the
                            functionalities of the{" "}
                            <a href="https://gusto.com/product/payroll">
                              Gusto tool
                            </a>{" "}
                            using advanced integration and workflows. 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            Gusto's chatbot is a computer software that
                            simulates human communication. Users talk with these
                            technologies using a chat interface or by voice,
                            exactly as they would with another person. Chatbots
                            understand the words spoken to them and respond with
                            a pre-programmed response.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            Chatbots, like ordinary apps, contain application
                            layers, databases, conversational user interfaces
                            (CUIs), and application programming interfaces
                            (APIs). There are three types of chatbots accessible
                            today:{" "}
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Rule-based chatbots:
                            </span>
                            The most basic option, these bots merely respond to
                            extremely precise inquiries with a pre-defined
                            response. These bots are ideal for tasks such as
                            lead qualification or providing clients with an
                            <a href="https://workativ.com/conversational-ai-platform/faq-chatbot-builder">
                              {" "}
                              engaging FAQ experience.
                            </a>
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Intelligent chatbots:
                            </span>
                            These intelligent bots learn from the user's
                            requests and information using machine learning, or
                            "ML." Intellectual bots are taught to recognise
                            certain words and phrases that prompt a response.
                            They gradually train themselves to comprehend more
                            queries and provide better replies.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              AI-powered chatbots:
                            </span>
                            These bots tackle user issues by combining the
                            advantages of rule-based bots with the strength of
                            cognitively autonomous programmes. They can recall
                            conversational context and comprehend user
                            preferences. To comprehend clients, these bots
                            utilize a mix of natural language processing,
                            machine learning, and AI. Natural language
                            processing improves the naturalness of
                            human-computer interactions.
                          </p>
                        </div>

                        {/* Section 3 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 market_wrapper_page"
                          id="section3"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            What is Gusto chatbot used for?
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            By using Gusto Chatbot, you streamline your
                            workplace HR support to connect employees,
                            processes, and automations into a transparent
                            workflow. Let’s take a look at some of the
                            automations you get with Gusto Chatbot
                          </p>

                          <ul
                            class="font-section-normal-text"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                                Employee Onboarding
                              </a>{" "}
                            </li>
                            <li>Leave Management </li>
                            <li>Track Employee Time Offs </li>
                            <li>
                              Keep employees on the loop about their payroll {" "}
                            </li>
                          </ul>
                        </div>

                        {/* Section 4 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section4"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            Best Gusto Chatbot platform
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            Workativ Assistant - no code platform with both
                            chatbot and workflow automation tools.
                          </p>
                        </div>
                        <NocodeWrapper />
                        {/* Section 5 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 mb-0 market_wrapper_page"
                          id="section5"
                        >
                          <h2 className="font-section-sub-header-small-bold">
                            Smarter AI-powered Gusto automations with Gusto
                            Chatbot
                          </h2>

                          <p className="font-section-normal-text line-height-2">
                            Here are some of the{" "}
                            <a href="https://workativ.com/conversational-ai-platform/gusto-chatbot">
                              &nbsp;Gusto Chatbot
                            </a>
                            &nbsp;tasks that Workativ can take care of for
                            you.   
                          </p>

                          <h2 class="font-section-normal-text-medium mb-3">
                            1. Gusto Employee Onboarding
                          </h2>

                          <p class="font-section-normal-text line-height-2">
                            <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                              Onboarding
                            </a>{" "}
                            includes substantial paperwork, signing contracts,
                            completing benefits paperwork, and other required
                            duties. The processing time for all the different
                            documents needed can be reduced to a few hours
                            rather than a week with Workativ’s Gusto
                            process automation. HR personnel will just have to
                            provide 
                            <a href="https://workativ.com/conversational-ai-platform">
                              Workativ
                            </a>
                             with the required details about the new employee on
                            their Slack or Teams and Workativ easily adds them
                            to Gusto. This helps to ensure that newly recruited
                            workers receive the appropriate equipment and system
                            access grants promptly.  
                          </p>

                          <h2 class="font-section-normal-text-medium mb-3">
                            2. Leave Management 
                          </h2>
                          <p class="font-section-normal-text line-height-2">
                            Manually calculating and approving leave requests is
                            difficult, as the HR department must review the
                            balance of leave, receive the approval of the
                            manager, and record the leave for payroll
                            calculation. This time-consuming procedure also
                            makes it difficult for the HR management and staff
                            to accept the leave requests on time.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            With Workativ’s automated Gusto leave approval
                            systems,
                            <a href="https://workativ.com/use-cases/leave-management-automation">
                              &nbsp;HR automation of leave management
                            </a>{" "}
                            can help secure approvals quicker while also
                            enabling employees’ leave records to be updated
                            instantly. This will help companies monitor, handle,
                            and authorize employee leaves much more
                            efficiently.  
                          </p>

                          <h2 className="font-section-sub-header-small-bold">
                            Workativ&nbsp; and Gusto: The Perfect Team to
                            Automate Employee Onboarding and Offboarding 
                          </h2>

                          <p className="font-section-normal-text line-height-2">
                            Not only can you automate Gusto tasks with Workativ,
                            but you can also combine Gusto tasks with other app
                            tasks into one single effective automation via Gusto
                            integrations. Let’s take a look at some of them.  
                          </p>

                          <h2 class="font-section-normal-text-medium mb-3">
                            1. Efficient Onboarding/Offboarding 
                          </h2>

                          <p class="font-section-normal-text line-height-2">
                            You can set up an automation with Workativ after
                            connecting your Gusto, Office 365, and Slack account
                            such that a new employee gets added to the company’s
                            Office 365 email distribution list
                            after Workativ retrieves the employees’ email from
                            Gusto and then a message is sent to the company’s 
                            <a href="https://workativ.com/conversational-ai-platform/slack-chatbot">
                              &nbsp;Slack channel
                            </a>{" "}
                            by Workativ about the new employee. And when an
                            employee gets terminated, help desk agents can
                            use Workativ to create a ticket on tools like Jira
                            Service Desk so that the employee experiences a{" "}
                            <a href="https://workativ.com/use-cases/employee-offboarding-automation">
                              smooth offboarding experience.
                            </a>
                              
                          </p>

                          <h2 class="font-section-normal-text-medium mb-3">
                            2. Track Employee Time Offs 
                          </h2>
                          <p class="font-section-normal-text line-height-2">
                            With Workativ’s Gusto automation, time off approvals
                            becomes simpler with Gusto. On their Slack or
                            Microsoft Teams chat, any employee can simply
                            use Workativ on their mobile phone to{" "}
                            <a href="https://workativ.com/use-cases/leave-management-automation">
                              request time off.
                            </a>{" "}
                            A notification is sent immediately to the manager
                            for approval via email and everything is entered in
                            Gusto automatically by Workativ. The employee then
                            receives a confirmation from Workativ on
                            their Slack or Microsoft Teams chat as soon as the
                            manager responds to the leave request. Simple,
                            right?  
                          </p>
                          <h2 class="font-section-normal-text-medium mb-3">
                            3. Keep employees on the loop about their payroll 
                          </h2>
                          <p class="font-section-normal-text line-height-2">
                            By integrating Workativ with Gusto, you can set up
                            an automation that enables employees to view their
                            salary details with ease. While calculating
                            <a href="https://workativ.com/use-cases/payroll-enquiry-automation">
                              &nbsp;payroll
                            </a>{" "}
                            for employees, HR personnel can also easily view
                            employee details on Workativ like the number of
                            leaves taken by the employee, etc., This makes the
                            payroll calculation process much faster and
                            error-free. 
                          </p>
                        </div>

                        {/* Section 6 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section6"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            How to Build a Gusto chatbot
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            Now that we’ve seen the endless possibilities of
                            what integrating Workativ and Gusto and your
                            existing apps can do for you, let’s see how you can
                            go about the same.
                          </p>
                          <h3 className="font-section-normal-text-medium">
                            Step 1. Creating a bot workspace
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            When you sign up for Workativ Assistant, you’ll be
                            required to name your workspace. You can provide the
                            name of  your organization as a workspace name.    
                          </p>
                          <img
                            loading="lazy"
                            src={image1}
                            className="mb-5"
                          ></img>
                          <h3 className="font-section-normal-text-medium">
                            Step 2. Download your bot
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Download a prebuilt Gusto bot from the bot
                            Marketplace    
                          </p>
                          <img
                            loading="lazy"
                            src={image2}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image3}
                            className="mb-5"
                          ></img>
                          <p className="font-section-normal-text line-height-2">
                            So that’s all there is to the initial set up of your
                            workspace. Next, let’s download Gusto workflows
                          </p>
                          <h3 className="font-section-normal-text-medium">
                            Step 3. Setting up a Gusto app workflow 
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-1 pb-0">
                            Lets download Gusto app workflow from marketplace   
                          </p>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Click on Download from Marketplace  
                          </p>
                          <img
                            loading="lazy"
                            src={image4}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Select the Gusto application   
                          </p>
                          <img
                            loading="lazy"
                            src={image5}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Select the app workflow  
                          </p>
                          <img
                            loading="lazy"
                            src={image6}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Connect with your Gusto account    
                          </p>
                          <img
                            loading="lazy"
                            sr={image7}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image8}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image9}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Test the app workflow   
                          </p>
                          <img
                            loading="lazy"
                            src={image10}
                            className="mb-5"
                          ></img>

                          <h3 className="font-section-normal-text-medium">
                            Step 4: Now add workflow to the Gusto chatbot 
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Add app workflow to the dialog conversation  
                          </p>
                          <img
                            loading="lazy"
                            src={image11}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Select the workflow  
                          </p>
                          <img
                            loading="lazy"
                            src={image12}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Provide the inputs & save it   
                          </p>
                          <img
                            loading="lazy"
                            src={image13}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image14}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Test your Gusto chatbot    
                          </p>
                          <img
                            loading="lazy"
                            src={image15}
                            className="mb-5"
                          ></img>

                          <h3 className="font-section-normal-text-medium mb-0 pb-0">
                            Step 5: Deploy Gusto chatbot in your favourite chat
                            channel   
                          </h3>
                          <img
                            loading="lazy"
                            src={image16}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image17}
                            className="mb-3"
                          ></img>
                        </div>

                        <div className="build_needs">
                          <div class="col-lg-12 col-md-12 col-12 pl-0 center_features_header">
                            <h2 className="font-section-header mb-3">
                              Steps to build HR Chatbot
                            </h2>
                          </div>
                        </div>

                        <VideoBlog
                          videoURL={
                            "https://www.youtube-nocookie.com/embed/ymkNx2zMLAE?autoplay=1"
                          }
                          imgURL={VidImg}
                        />

                        {/* Section 7 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 mb-0 market_wrapper_page"
                          id="section7"
                        >
                          <h2 className="font-section-sub-header-small-bold">
                            Business benefits of&nbsp; Gusto chatbot with
                            Workativ
                          </h2>
                          <p class="font-section-normal-text line-height-2">
                            Now the benefits are enormous when it comes to
                            integrating and automating Gusto tasks
                            with Workativ. 
                          </p>
                          <h3 className="font-section-normal-text-medium">
                            1. Streamlined efficient processes 
                          </h3>

                          <p className="font-section-normal-text line-height-2">
                            With Workativ’s Gusto process automation,{" "}
                            <a href="https://workativ.com/assistant/hr-automation-with-ai-chatbot">
                              HR processes
                            </a>{" "}
                            become much simpler and transparent. HR personnel
                            can onboard/offboard employees in a jiff, employees
                            can request and get approval for time offs in an
                            instant, and HR personnel can view details about an
                            employee on-the-go using Workativ on their Slack or
                            Microsoft Teams
                            via Workativ’s Gusto Slack integration. 
                          </p>

                          <h5 className="font-section-normal-text-medium">
                            2. Effective records management 
                          </h5>
                          <p className="font-section-normal-text line-height-2">
                            Instead of having to log in to a static portal every
                            time to apply for time offs, employees can easily
                            bring up Workativ on their mobile phone or
                            laptop/desktop and{" "}
                            <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                              ask&nbsp; Workativ to apply for&nbsp; time
                              off.&nbsp;
                            </a>
                             Workativ sends out approval requests to managers
                            and once approved, informs the employee about the
                            same. Workativ then records the time off on Gusto.
                            HR personnel can use Workativ to view time offs of
                            an employee while calculating payroll. 
                          </p>

                          <h5 className="font-section-normal-text-medium">
                            3. Better resource allocation   
                          </h5>
                          <p className="font-section-normal-text line-height-2">
                            Workativ immediately informs managers when an
                            employee applies for time off so that managers can
                            allocate resources for tasks during that employees’
                            absence.
                          </p>
                        </div>

                        {/* Section 8*/}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section8"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            Conclusion  
                          </h3>
                          <ol
                            className="font-section-normal-text"
                            style="float: left; width: 100%;"
                          >
                            <li>
                              Increased efficiency because it's a virtual
                              assistant that can handle complex conversations
                              and tasks. 
                            </li>
                            <li>
                              Reduced time spent on routine tasks because the
                              chatbot can help you with things you would
                              normally do manually. 
                            </li>
                            <li>
                              Increased communication with colleagues and
                              customers as the chatbot is able to understand
                              natural language. 
                            </li>
                            <li>
                              Reduced risk of miscommunication as the chatbot is
                              able to learn from past interactions and respond
                              accordingly.
                            </li>
                          </ol>
                          <p class="font-section-normal-text line-height-2">
                            Workativ allows you to make an AI bot for your
                            office in just a few minutes, without having to
                            write any code. Workativ is a no-code SaaS platform
                            that lets organisations create, automate, and deploy
                            conversational AI chatbots in less than 60 minutes.
                            <a href="https://workativ.com/conversational-ai-platform/gusto-chatbot">
                              &nbsp;Try our Gusto chatbot for FREE now.&nbsp;
                            </a>
                            It's easy to use, and by automating tasks, it can
                            save you time. So why not try it out right now?
                          </p>
                        </div>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a
                                className="blog_usecase_link font-section-normal-text"
                                href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot

                        "
                              >
                                A Guide to Conversational AI Chatbots
                              </a>
                            </li>
                            <li>
                              <a
                                className="blog_usecase_link font-section-normal-text"
                                href="https://workativ.com/conversational-ai-platform/5-benefits-of-introducing-hr-bots-to-support-remote-workers"
                              >
                                5 Benefits of Introducing HR Bots to Support
                                Remote Workers
                              </a>
                            </li>
                            <li>
                              <a
                                className="blog_usecase_link font-section-normal-text"
                                href="https://workativ.com/conversational-ai-platform/ms-teams-bot-for-hrms"
                              >
                                The Future of HRMs is Here with MS Teams Bots
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper margin-no-code">
      <h4>No Code - Free Gusto Chatbot</h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="gusto chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
